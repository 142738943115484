import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ArticlePage from '~/components/Article/Page'

const ArticleTemplate = ({ data }) => {
  const { article } = data

  return (
    <Layout>
      <Metadata title={article.title} />
      <ArticlePage article={article} />
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String, $locale: String) {
    article: contentfulArticle(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ArticlePageFragment
    }
  }
`

export default ArticleTemplate
