import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Container, Heading, Text } from 'theme-ui'
import Author from '~/components/Article/Author'

const ArticlePage = ({ article }) => {
  const { title, author, body, image } = article

  return (
    <Container>
      <Heading
        as="h2"
        variant="h2"
        sx={{
          textAlign: ['center', null, 'left'],
          color: 'primary',
          variant: ['text.h3', 'text.h3', 'text.h2'],
          marginTop: ['16px', null, '36px'],
          marginBottom: ['8px', null, '32px']
        }}
      >
        {title}
      </Heading>

      {image && (
        <Box sx={{ width: '100%' }}>
          <GatsbyImage
            image={getImage(image)}
            alt={image.title}
            sx={{
              height: '100%',
              minHeight: '100px',
              maxHeight: ['240px', '280px', '450px']
            }}
          />
        </Box>
      )}

      {author && <Author name={author.name} />}

      <Text
        sx={{
          variant: 'text.body',
          color: 'primary',
          textAlign: ['center', null, 'left'],
          '& p': {
            img: {
              width: '100%'
            }
          }
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html
          }}
        />
      </Text>
    </Container>
  )
}

ArticlePage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string
      }),
      bio: PropTypes.shape({})
    })
  })
}

export default ArticlePage
